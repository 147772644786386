import { colors } from "./foundations/colors";
import { fontFamily } from "./foundations/fontFamily";
import { fontWeight } from "./foundations/fontWeight";
import { lineHeight } from "./foundations/lineHeight";
import { fontSize } from "./foundations/fontSize";
import { borderRadius } from "./foundations/borderRadius";
import { borderWidth } from "./foundations/borderWidth";
import { opacity } from "./foundations/opacity";
import { shadow } from "./foundations/shadow";

export const defaultTheme = {
  colors,
  fontFamily,
  fontWeight,
  lineHeight,
  fontSize,
  borderRadius,
  borderWidth,
  opacity,
  shadow,
} as const;
