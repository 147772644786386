import { useEffect, useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { MdStore } from "react-icons/md";
import { ShopsGrantedCredentials } from "../../@types/Auth";
import RnrPhoto from "../../assets/images/stores/warehouse.png";
import { SearchInput } from "../../components/SearchInput";
import { SelectInput } from "../../components/SelectInput";
import { useAuth } from "../../hooks/auth";
import { SelectStorePhoto } from "../../shared/SelectStorePhoto";

import { TenantType } from "../../@types/Stores";
import { wrapperRequests } from "../../services/api";
import { routesURL } from "../../services/routesUrl";
import * as S from "./styles";

interface Filters {
  tenantName: string;
  shopName: string;
}

export function Dashboard() {
  const { user, shopSelected, selectStore } = useAuth();

  const [allTenants, setAllTenants] = useState<TenantType[]>([]);

  const [filters, setFilters] = useState<Filters>({
    tenantName: "",
    shopName: "",
  });

  const selectedShop = user.user.shopsGranted.filter(
    (shop) => shop.name === (shopSelected && shopSelected.name),
  );

  const storeRegionsOptions = allTenants.map((tenantName) => ({
    id: tenantName.id,
    value: tenantName.name,
    label: tenantName.name,
  }));

  const filterStores = user.user.shopsGranted.filter((shop) => {
    const isSameSelectedShop =
      selectedShop[0] && shop.name === selectedShop[0].name;

    const nameFilter =
      !isSameSelectedShop &&
      shop.name
        .toLocaleLowerCase()
        .includes(filters.shopName.toLocaleLowerCase());

    const tenantNameFilter =
      shop.tenantName &&
      shop.tenantName
        .toLocaleLowerCase()
        .includes(filters.tenantName.toLocaleLowerCase());

    return nameFilter && tenantNameFilter;
  });

  const filteredStores = filterStores.sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const shopsToShow = selectedShop.concat(filteredStores);

  const handleSelectStore = (shop: ShopsGrantedCredentials) => {
    selectStore(shop);
  };

  const fetchAllTenants = async () => {
    try {
      const { data } = await wrapperRequests(
        routesURL.settings.stores.getAllTenants,
        "GET",
      );

      setAllTenants(data.content);
    } catch (error) {
      throw Error(String(error));
    }
  };

  const handleUpdateFilter = (filter: keyof Filters, value: string) => {
    setFilters((state) => ({
      ...state,
      [filter]: value,
    }));
  };

  useEffect(() => {
    fetchAllTenants();
  }, []);

  return (
    <S.StoreContainer>
      <S.StoreHeader>
        <S.StoreFilterContainer>
          <span>Select Region</span>
          <SelectInput
            placeholder="Select a option"
            options={storeRegionsOptions}
            onChange={(event) => {
              handleUpdateFilter("tenantName", event.target.value);
            }}
          />
        </S.StoreFilterContainer>

        <S.StoreFilterContainer>
          <span>Search</span>
          <SearchInput
            searchIcon
            placeholder="Store name"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleUpdateFilter("shopName", event.target.value);
            }}
          />
        </S.StoreFilterContainer>
      </S.StoreHeader>

      <S.StoresContainer>
        {shopsToShow.map((store) => {
          return (
            <S.StoresCardContainer key={store.id}>
              <S.StoreCardContent>
                <img
                  src={
                    SelectStorePhoto(store.name)
                      ? SelectStorePhoto(store.name)
                      : RnrPhoto
                  }
                  alt=""
                />
                <S.StoreCardInfoContent>
                  <MdStore size={16} />
                  <span>{store.name.toLocaleLowerCase()}</span>
                </S.StoreCardInfoContent>
                <S.StoreCardInfoContent>
                  <HiLocationMarker size={16} />
                  <span>
                    {store.name === "Warehouse"
                      ? "San Dimas"
                      : store.name.toLocaleLowerCase()}
                    , {store.state}
                  </span>
                </S.StoreCardInfoContent>

                <button
                  disabled={store.name === shopSelected.name}
                  onClick={() => handleSelectStore(store)}
                >
                  {store.name === shopSelected.name ? "Selected" : "Access"}
                </button>
              </S.StoreCardContent>
            </S.StoresCardContainer>
          );
        })}
      </S.StoresContainer>
    </S.StoreContainer>
  );
}
