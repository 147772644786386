import { Button } from "../../../../../../../components/Button";
import { FormInput } from "../../../../../../../components/FormElements/FormInput";
import { SelectInput } from "../../../../../../../components/SelectInput";
import { WebPrintersList } from "../../WebPrintersList";
import printersData from "./fakePrinters.json";

import * as S from "./styles";

export function PrintersStore() {
  const error = "";

  return (
    <S.StorePrintersForm>
      <S.StoreFormTitle>Add printers</S.StoreFormTitle>

      <S.StoreInputAlign>
        <FormInput
          title='Printer Serial Number'
          type='text'
          placeholder='Enter Printer Serial Number'
          autoComplete='new-password'
          error={error}
        />

        <SelectInput
          variant='form'
          title='Description'
          placeholder='Select Description'
          error={error}
          options={[{ id: "1", value: "west", label: "West" }]}
          onChange={(e) => {
            e.preventDefault();
          }}
        />
      </S.StoreInputAlign>

      <S.StoreInputAlign>
        <FormInput
          title='Costumer Key'
          type='email'
          placeholder='Enter Costumer Key'
          autoComplete='new-password'
          error={error}
        />

        <FormInput
          title='Costumer Secret'
          placeholder='Enter Costumer Secret'
          autoComplete='new-password'
          error={error}
        />
      </S.StoreInputAlign>

      <S.StoreInputAlign>
        <FormInput
          title='Costumer Tenant'
          placeholder='Enter Costumer Tenant'
          autoComplete='new-password'
          error={error}
        />

        <Button
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Add
        </Button>
      </S.StoreInputAlign>

      <S.StorePrintersContainer>
        <WebPrintersList printersData={printersData} />
      </S.StorePrintersContainer>
    </S.StorePrintersForm>
  );
}
