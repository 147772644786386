import { ReactNode } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import * as S from "./styles";

interface DropdownProps {
  children: ReactNode;
}

export function Dropdown({ children }: DropdownProps) {
  return (
    <DropdownMenu.Portal>
      <S.DropdownContent sideOffset={12}>
        <S.DropdownArrow />
        {children}
      </S.DropdownContent>
    </DropdownMenu.Portal>
  );
}
