import styled from "styled-components";

export const StoreFormTitle = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors["gray-900"]};

  margin: 2rem 0 1rem;
`;

export const StoreDetailsForm = styled.form``;

export const StoreInputAlign = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  gap: 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  max-width: 728px;
  padding: 2.5rem 0;
`;
