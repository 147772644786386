import { FormEvent, useState } from "react";
import ReactLoading from "react-loading";
import { useContextSelector } from "use-context-selector";
import { StoreTypes } from "../../../../../../../@types/Stores";
import { Button } from "../../../../../../../components/Button";
import { FormInput } from "../../../../../../../components/FormElements/FormInput";
import { SelectInput } from "../../../../../../../components/SelectInput";
import { StoresContext } from "../../../../../../../contexts/StoresContext";
import {
  StoreRegionsOptions,
  StoreStatusOptions,
  StoreTypeOptions,
} from "../../../../../../../shared/DataToSelectInput";

import * as S from "./styles";

interface DetailsStoreProps {
  selectedStore: StoreTypes;
  storeId: string;
}

export function DetailsStore({ selectedStore, storeId }: DetailsStoreProps) {
  const { editStore, storesLoading } = useContextSelector(
    StoresContext,
    (context) => {
      return context;
    }
  );

  const [, setStoreEdited] = useState(selectedStore);

  const checkIfStatusExists =
    selectedStore.active === undefined && "Select options";

  const error = "";

  const handleEditStore = async (event: FormEvent) => {
    event.preventDefault();

    const storePrinter = {
      ...selectedStore,
      printers: [
        {
          serialNumber: "50J183900416",
          tag: "Chino Printer 1",
          activeForOrderItem: false,
          size: "Small - 2.25 x 1.25",
          activeForProductPrice: false,
          activeForWarehouseLabel: false,
        },
        {
          serialNumber: "50N202703089",
          tag: "Chino Printer 2 - Large",
          activeForOrderItem: true,
          size: "Large - 2.25 x 3",
          activeForProductPrice: false,
          activeForWarehouseLabel: false,
        },
        {
          serialNumber: "50N202766666",
          tag: "Chino Printer 6",
          activeForOrderItem: true,
          size: "Small - 2.25 x 1.25",
          activeForProductPrice: false,
          activeForWarehouseLabel: false,
        },
      ],
    } as StoreTypes;

    await editStore(storePrinter, storeId);
  };

  return (
    <S.StoreDetailsForm onSubmit={handleEditStore}>
      <S.StoreFormTitle>Details about the store</S.StoreFormTitle>

      {!selectedStore.name ? (
        <ReactLoading height={24} width={24} type={"spin"} color='#000' />
      ) : (
        <>
          <S.StoreInputAlign>
            <FormInput
              title='Store name'
              type='text'
              placeholder='Enter store name'
              autoComplete='new-password'
              error={error}
              defaultValue={selectedStore.name && selectedStore.name}
              onChange={(value) => {
                setStoreEdited({
                  ...selectedStore,
                  name: value.target.value,
                });
              }}
            />

            <SelectInput
              variant='form'
              title='Region'
              error={error}
              placeholder={
                selectedStore.region ? selectedStore.region : "Select options"
              }
              options={StoreRegionsOptions}
              onChange={(value) => {
                setStoreEdited({
                  ...selectedStore,
                  region: value.target.value,
                });
              }}
            />
          </S.StoreInputAlign>

          <S.StoreInputAlign>
            <FormInput
              title='Email'
              type='email'
              placeholder='Enter email'
              autoComplete='new-password'
              error={error}
              required={
                selectedStore.email ? selectedStore.email.length > 0 : false
              }
              defaultValue={selectedStore.email && selectedStore.email}
              onChange={(value) => {
                setStoreEdited({
                  ...selectedStore,
                  email: value.target.value,
                });
              }}
            />

            <FormInput
              title='Phone'
              isPhone
              autoComplete='new-password'
              error={error}
              phonePlaceholder={
                selectedStore.phone && selectedStore.phone.substring(2)
              }
              phoneOnChange={(value) => {
                setStoreEdited({
                  ...selectedStore,
                  phone: value && value,
                });
              }}
            />
          </S.StoreInputAlign>

          <S.StoreInputAlign>
            <SelectInput
              variant='form'
              title='Status'
              error={error}
              options={StoreStatusOptions}
              placeholder={
                selectedStore.active
                  ? "Active"
                  : checkIfStatusExists || "Inactive"
              }
              onChange={(value) => {
                setStoreEdited({
                  ...selectedStore,
                  active: value.target.value === "true" ? true : false,
                });
              }}
            />

            <SelectInput
              variant='form'
              title='Store Type'
              error={error}
              options={StoreTypeOptions}
              placeholder={
                selectedStore.type
                  ? selectedStore.type.toLowerCase()
                  : "Select options"
              }
              onChange={(value) => {
                setStoreEdited({
                  ...selectedStore,
                  type: value.target.value === "SHOP" ? "SHOP" : "WAREHOUSE",
                });
              }}
            />
          </S.StoreInputAlign>

          <S.StoreInputAlign>
            <FormInput
              title='Geolocation - Latitude'
              type='text'
              placeholder='Enter latitude'
              autoComplete='new-password'
              error={error}
              defaultValue={
                selectedStore.geolocationLat && selectedStore.geolocationLat
              }
              onChange={(value) => {
                setStoreEdited({
                  ...selectedStore,
                  geolocationLat: value.target.value,
                });
              }}
            />

            <FormInput
              title='Geolocation - Longitude'
              type='text'
              placeholder='Enter longitude'
              autoComplete='new-password'
              error={error}
              defaultValue={
                selectedStore.geolocationLon && selectedStore.geolocationLon
              }
              onChange={(value) => {
                setStoreEdited({
                  ...selectedStore,
                  geolocationLon: value.target.value,
                });
              }}
            />
          </S.StoreInputAlign>
        </>
      )}

      <S.ButtonContainer>
        <Button disabled={storesLoading} type='submit'>
          {storesLoading ? (
            <ReactLoading height={24} width={24} type={"spin"} color='#fff' />
          ) : (
            "Save"
          )}
        </Button>
      </S.ButtonContainer>
    </S.StoreDetailsForm>
  );
}
