import { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";
import * as S from "./styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "ghost" | "gray";
  children: ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, children, ...rest }, ref) => {
    return (
      <S.ButtonContainer ref={ref} variant={variant} {...rest}>
        {children}
      </S.ButtonContainer>
    );
  },
);
