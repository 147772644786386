import SanDimasPhoto from "../assets/images/stores/sanDimas.png";
import ChinoPhoto from "../assets/images/stores/chino.png";
import PasadenaPhoto from "../assets/images/stores/pasadena.png";
import RanchoPhoto from "../assets/images/stores/rancho.png";
import SantaClaritaPhoto from "../assets/images/stores/santaClarita.png";
import WarehousePhoto from "../assets/images/stores/warehouse.png";

export function SelectStorePhoto(storeName: string) {
  let storePhoto;

  switch (storeName) {
    case "San Dimas":
      storePhoto = SanDimasPhoto;
      break;
    case "Pasadena":
      storePhoto = PasadenaPhoto;
      break;
    case "Chino":
      storePhoto = ChinoPhoto;
      break;
    case "Rancho Cucamonga":
      storePhoto = RanchoPhoto;
      break;
    case "Warehouse":
      storePhoto = WarehousePhoto;
      break;
    case "Santa Clarita":
      storePhoto = SantaClaritaPhoto;
      break;
    default:
      break;
  }

  return storePhoto;
}
