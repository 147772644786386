import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled from "styled-components";

export const DropdownContent = styled(DropdownMenu.Content)`
  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow["Level 1"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};

  padding: 1rem 0.75rem;
  gap: 0.5rem;

  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
`;

export const DropdownArrow = styled(DropdownMenu.Arrow).attrs({
  width: 16,
  height: 8,
  fill: "#E5E5E5",
})``;
