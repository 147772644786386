import styled, { css } from "styled-components";

export const ItemDetailsCardContainer = styled.main`
  display: flex;
  flex-direction: column;

  background-color: ${(props) => props.theme.colors.white};

  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: ${(props) => props.theme.borderRadius.MD};
  padding-bottom: 2rem;

  width: 352px;
  height: 504px;
`;

export const ItemDetailsCardTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};

  padding: 1.5rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
`;

interface ItemDetailsContentProps {
  search?: boolean;
}

export const ItemDetailsContent = styled.div<ItemDetailsContentProps>`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 1rem;

  padding: 0 1.5rem 0 2rem;
  padding-top: 1.5rem;

  height: 100%;

  ${(props) => props.search && css``};

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors["gray-400"]};
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors["gray-700"]};
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  padding-right: 4px;

  input {
    cursor: pointer;
  }
`;

export const FilterButton = styled.button`
  padding: 8px 0;
  border: none;
  text-align: left;

  width: 100%;

  color: ${(props) => props.theme.colors["gray-800"]};
  background: ${(props) => props.theme.colors.white};
`;

export const StockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ItemDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemDetailsProperty = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Bold};
  font-family: ${(props) => props.theme.fontFamily.Sora};

  color: ${(props) => props.theme.colors["black-750"]};
`;

export const ItemDetailsValueContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 0 8px;

  background-color: ${(props) => props.theme.colors["gray-200"]};
  border-radius: 4px;
  height: 2rem;
`;

export const ItemDetailsValue = styled.span`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  color: ${(props) => props.theme.colors["gray-800"]};
`;

export const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
`;
