import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useContextSelector } from "use-context-selector";
import { StoreTypes } from "../../../../../../@types/Stores";
import { PageHeader } from "../../../../../../components/Headers/PageHeader";
import { StoresContext } from "../../../../../../contexts/StoresContext";
import { CreateStoreStepsHeader } from "../CreateStoreStepsHeader";
import { ApisStore } from "./ApisStore";
import { DetailsStore } from "./DetailsStore";
import { ImagesStore } from "./ImagesStore";
import { PrintersStore } from "./PrintersStore";

import { wrapperRequests } from "../../../../../../services/api";

import { routesURL } from "../../../../../../services/routesUrl";
import * as S from "./styles";

export function CreateStore() {
  const { createStoreStep } = useContextSelector(StoresContext, (context) => {
    return context;
  });

  const [selectedStore, setSelectedStore] = useState({} as StoreTypes);

  const navigate = useNavigate();
  const { storeId } = useParams();
  const storeIdString = storeId ? storeId : "";

  const loadUserToEdit = async () => {
    try {
      const response = await wrapperRequests(
        routesURL.settings.stores.getStore(storeId || ""),
        "GET",
      );

      setSelectedStore(response.data);
    } catch (error) {
      toast.error("Store does not exist.", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/settings/stores");
    }
  };

  useEffect(() => {
    loadUserToEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.CreateStoreContainer>
      <PageHeader
        hasBorder={false}
        title={selectedStore.name ? "Edit Store" : "Create new Store"}
        customBreadcrumbString={
          selectedStore.name ? "Edit Store" : "Create new Store"
        }
      />

      <CreateStoreStepsHeader />

      {createStoreStep === "details" && (
        <DetailsStore selectedStore={selectedStore} storeId={storeIdString} />
      )}
      {createStoreStep === "images" && <ImagesStore />}
      {createStoreStep === "printers" && <PrintersStore />}
      {createStoreStep === "api" && <ApisStore />}
    </S.CreateStoreContainer>
  );
}
