import styled from "styled-components";

export const SettingsContainer = styled.main`
  flex: 1;

  width: 100%;
  min-height: 100vh;

  background-color: ${(props) => props.theme.colors["white-300"]};

  padding: 3rem;
`;

export const SettingsCardButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 1.5rem;
`;
