import UploadIcon from "../../../../../../../assets/icons/upload.svg";
import Separator from "../../../../../../../assets/icons/separator.svg";

import * as S from "./styles";
import { useState } from "react";

export function ImagesStore() {
  const [image, setImage] = useState();
  const [logoImage, setLogoImage] = useState();

  const handleFileInput = (e: any, type: string) => {
    type === "logo"
      ? setLogoImage(e.target.files[0])
      : setImage(e.target.files[0]);
  };

  return (
    <S.StoreImageForm>
      <S.StoreFormTitle>Customize images from your store</S.StoreFormTitle>

      <S.StoreImageContainer>
        <S.StoreImageWrapper>
          <input
            type='file'
            id='logo'
            accept='image/*'
            onChange={(e) => handleFileInput(e, "logo")}
          />

          <S.StoreImagePicture htmlFor='logo'>
            {logoImage ? (
              <img src={URL.createObjectURL(logoImage)} alt='logo' />
            ) : (
              <S.StoreImageIcon />
            )}
          </S.StoreImagePicture>

          <S.StoreImageAlignContainer>
            <S.StoreImageDescription>Store logo</S.StoreImageDescription>

            <S.SendImageButton
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Send Image
              <img src={Separator} alt='separator' />
              <img src={UploadIcon} alt='upload' />
            </S.SendImageButton>
          </S.StoreImageAlignContainer>
        </S.StoreImageWrapper>

        <S.StoreImageWrapper>
          <input
            type='file'
            id='image'
            accept='image/*'
            onChange={(e) => handleFileInput(e, "image")}
          />

          <S.StoreImagePicture htmlFor='image'>
            {image ? (
              <img src={URL.createObjectURL(image)} alt='logo' />
            ) : (
              <S.StoreImageIcon />
            )}
          </S.StoreImagePicture>

          <S.StoreImageAlignContainer>
            <S.StoreImageDescription>Store Image</S.StoreImageDescription>

            <S.SendImageButton
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              Send Image
              <img src={Separator} alt='separator' />
              <img src={UploadIcon} alt='upload' />
            </S.SendImageButton>
          </S.StoreImageAlignContainer>
        </S.StoreImageWrapper>
      </S.StoreImageContainer>
    </S.StoreImageForm>
  );
}
