import styled, { css } from "styled-components";

interface SelectInputContainerProps {
  variant?:
    | "form"
    | "pagination"
    | "selectStores"
    | "small"
    | "exploreItems"
    | "profile"
    | "roles";

  disabled?: boolean;
}

export const SelectInputContainer = styled.div<SelectInputContainerProps>`
  display: flex;
  flex-direction: column;

  span {
    font-size: ${(props) => props.theme.fontSize.XXXS};
    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors.black};

    margin-bottom: 0.5rem;

    ${({ variant }) =>
      variant === "form" &&
      css`
        margin-top: 1rem;
      `};

    ${({ variant }) =>
      variant === "profile" &&
      css`
        font-size: ${(props) => props.theme.fontSize.XXS};
        font-family: ${(props) => props.theme.fontFamily.Gantari};
        font-weight: ${(props) => props.theme.fontWeight.Regular};
        color: ${(props) => props.theme.colors["black-925"]};
        line-height: ${(props) => props.theme.lineHeight.Default};
      `};

    ${({ variant }) =>
      variant === "roles" &&
      css`
        font-size: ${(props) => props.theme.fontSize.XXS};
        font-weight: ${(props) => props.theme.fontWeight.Semibold};
        line-height: ${(props) => props.theme.lineHeight.Default};
        color: ${(props) => props.theme.colors["black-925"]};
      `};
  }
`;

export const ErrorAlignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectInputWrapper = styled.div<SelectInputContainerProps>`
  display: flex;
  position: relative;
  align-items: center;

  width: 16rem;
  max-height: 3rem;

  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};

  cursor: pointer;

  ${({ variant }) =>
    variant === "form" &&
    css`
      width: 22rem;
      max-height: 3rem;
    `};

  ${({ variant }) =>
    variant === "pagination" &&
    css`
      width: 5rem;
      height: 3rem;
    `};

  ${({ variant }) =>
    variant === "selectStores" &&
    css`
      width: 11rem;
    `};

  ${({ variant }) =>
    variant === "small" &&
    css`
      width: 12rem;
    `};

  ${({ variant }) =>
    variant === "exploreItems" &&
    css`
      width: 12rem;
      border-radius: 4px;
    `};

  &:hover {
    outline: 1px solid ${(props) => props.theme.colors["gray-650"]};
    transition: outline 0.2s;
  }

  ${({ variant, disabled }) =>
    variant === "profile" &&
    css`
      width: min(22rem, 100%);
      border-radius: 8px;
      height: 3rem;
      border: 1px solid ${(props) => props.theme.colors["gray-700"]};

      &:hover {
        outline: transparent;
      }

      ${disabled &&
      css`
        background-color: ${(props) => props.theme.colors["gray-400"]};
        opacity: 1;
        cursor: not-allowed;
      `}
    `};

  ${({ variant, disabled }) =>
    variant === "roles" &&
    css`
      width: min(28rem, 100%);
      border-radius: 8px;
      height: 3rem;

      &:hover {
        outline: transparent;
      }

      ${disabled &&
      css`
        background-color: ${(props) => props.theme.colors["gray-400"]};
        opacity: 1;
        cursor: not-allowed;
      `}
    `};

  select {
    font-family: ${(props) => props.theme.fontFamily.Sora};
    text-transform: capitalize;
    font-size: 1rem;
    color: ${(props) => props.theme.colors["gray-800"]};
    padding: 1rem;

    width: 16rem;
    max-height: 3.2rem;

    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    cursor: pointer;

    @media (max-width: 1050px) {
      width: 13rem;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    ${({ variant }) =>
      variant === "pagination" &&
      css`
        color: ${(props) => props.theme.colors["black-700"]};
      `};

    ${({ variant }) =>
      variant === "form" &&
      css`
        width: 22rem;
        font-size: ${(props) => props.theme.fontSize.XXXS};
      `};

    ${({ variant }) =>
      variant === "selectStores" &&
      css`
        font-size: ${(props) => props.theme.fontSize.XXXS};
      `};

    ${({ variant }) =>
      variant === "exploreItems" &&
      css`
        padding: 0.5rem;
        color: ${(props) => props.theme.colors["black-925"]};
        font-size: ${(props) => props.theme.fontSize.XXS};
      `};

    ${({ variant }) =>
      variant === "profile" &&
      css`
        width: min(22rem, 100%);
        height: 100%;
        padding: 0 1rem;
        border-radius: 8px;

        font-family: inherit;
        font-size: ${(props) => props.theme.fontSize.XXS};
        font-weight: ${(props) => props.theme.fontWeight.Regular};
        color: ${(props) => props.theme.colors["black-925"]};
        line-height: ${(props) => props.theme.lineHeight.Default};

        &:disabled {
          cursor: not-allowed;
          opacity: 1;
        }
      `};

    ${({ variant }) =>
      variant === "roles" &&
      css`
        width: min(28rem, 100%);
        height: 100%;
        padding: 0 1rem;
        border-radius: 8px;

        font-family: inherit;
        font-size: ${(props) => props.theme.fontSize.XXS};
        font-weight: ${(props) => props.theme.fontWeight.Regular};
        color: ${(props) => props.theme.colors["black-925"]};
        line-height: ${(props) => props.theme.lineHeight.Default};

        &:disabled {
          cursor: not-allowed;
          opacity: 1;
        }
      `};
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-top: 6px solid #aaa;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
`;
