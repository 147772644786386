import { FaCaretDown } from "react-icons/fa";
import styled from "styled-components";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 5rem;

  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow.Header};

  padding: 0 3rem;
`;

export const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.MD};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors["gray-900"]};
  line-height: ${(props) => props.theme.lineHeight.Default};
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;

  button.arrow_btn {
    background-color: transparent;
    border: none;
  }
`;

export const GreetingsText = styled.label`
  font-size: ${(props) => props.theme.fontSize.XS};
  color: ${(props) => props.theme.colors["gray-900"]};
  line-height: ${(props) => props.theme.lineHeight.Default};
`;

export const UserName = styled.label`
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors["gray-900"]};
  line-height: ${(props) => props.theme.lineHeight.Default};

  text-decoration: underline;
`;

export const UserPhoto = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: ${(props) => props.theme.borderRadius.Circular};

  margin: 0 0.5rem 0 1rem;
  cursor: pointer;

  // Remove this after user photo already implement
  padding: 2px;
  background-color: ${(props) => props.theme.colors.black};
`;

export const ArrowDown = styled(FaCaretDown).attrs((props) => ({
  size: "1.5rem",
  color: props.theme.colors["gray-900"],
}))`
  cursor: pointer;
`;
