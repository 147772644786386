import styled from "styled-components";

export const CreateStoreContainer = styled.main`
  flex: 1;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 3rem;
`;

export const StoreForm = styled.form``;

export const StoreInputAlign = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  gap: 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  max-width: 728px;
  padding: 2.5rem 0;
`;
