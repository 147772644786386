import { GoLocation } from "react-icons/go";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { NavLink } from "react-router-dom";
import Switch from "react-switch";
import styled, { css } from "styled-components";

interface ResponsiveProps {
  responsiveLayout: boolean;
}

export const MenuContainer = styled.main<ResponsiveProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  background-color: ${(props) => props.theme.colors.black};
  width: 15rem;
  min-height: 100vh;

  padding: 0 1.5rem;

  img {
    height: 3rem;
    width: 7.82rem;

    margin: 2.5rem 0 1rem;

    &:hover {
      cursor: pointer;
    }
  }

  ${(props) =>
    props.responsiveLayout &&
    css`
      width: var(--sideBarWidthResponsive);
      padding: 0 0.5rem;
    `};
`;

export const MenuContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 1.5rem;

  height: 100vh;

  padding-bottom: 2rem;

  &&.responsive_layout {
    width: 100%;
  }
`;

export const ReduceArrowIcon = styled(IoIosArrowDropleft).attrs({
  size: 28,
})`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;

  color: ${(props) => props.theme.colors.white};
`;

export const ExpandArrowIcon = styled(IoIosArrowDropright).attrs({
  size: 28,
})`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  cursor: pointer;

  color: ${(props) => props.theme.colors.white};
`;

export const CentralStoreButton = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 3rem;

  background-color: ${(props) => props.theme.colors["blue-600"]};
  border: 0;
  border-radius: ${(props) => props.theme.borderRadius.XXLG};

  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  line-height: ${(props) => props.theme.lineHeight.Default};
  text-decoration: none;
  color: ${(props) => props.theme.colors.white};

  span {
    max-width: 8rem;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors["blue-650"]};
    outline: 2px solid ${(props) => props.theme.colors["blue-500"]};
    transition: all 0.1s;
  }

  &&.store_button_responsive {
    font-size: 20px;
    border-radius: ${(props) => props.theme.borderRadius.SM};
  }
`;

export const LocationIcon = styled(GoLocation).attrs({
  size: "1.25rem",
})`
  margin-right: 0.5rem;

  &&.location_icon_responsive {
    margin: 0;
  }
`;

export const MenuOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 1.5rem 0;
  gap: 1.5rem;

  width: 100%;

  border-width: ${(props) => props.theme.borderWidth.Hairline};
  border-color: ${(props) => props.theme.colors["gray-900"]};
  border-style: solid;
  border-right: 0;
  border-left: 0;
`;

export const MenuButton = styled(NavLink)`
  display: flex;
  align-items: center;

  padding: 0.75rem 1.5rem;

  width: 100%;
  height: 3rem;

  background-color: ${(props) => props.theme.colors.black};
  border: 0;
  border-radius: ${(props) => props.theme.borderRadius.SM};

  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  line-height: ${(props) => props.theme.lineHeight.Default};
  text-decoration: none;
  color: ${(props) => props.theme.colors["gray-700"]};

  svg {
    margin-right: 0.25rem;
  }

  transition:
    background-color 0.3s,
    color 0.3s;

  &:hover {
    background-color: ${(props) => props.theme.colors["gray-900"]};
    color: ${(props) => props.theme.colors.white};
  }

  &.active {
    color: ${(props) => props.theme.colors["gray-900"]};
    background-color: ${(props) => props.theme.colors["gray-750"]};
  }

  &&.menu_button_responsive {
    justify-content: center;
    padding: 0;

    svg {
      margin: 0;
    }
  }
`;

export const LockContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: 0;

  margin-top: auto;

  width: 100%;
  height: 2.5rem;

  p {
    color: ${(props) => props.theme.colors["gray-700"]};

    margin-left: 0.75rem;
  }
`;

export const VersionContainer = styled.div`
  p {
    font-style: italic;
    text-align: center;

    color: ${(props) => props.theme.colors["gray-700"]};
  }
`;

export const SwitchStyled = styled(Switch).attrs({
  onColor: "#0063F9",
  onHandleColor: "#ffffff",
  handleDiameter: 20,
  uncheckedIcon: false,
  checkedIcon: false,
  height: 18,
  width: 32,
})`
  .react-switch-handle,
  .react-switch-bg {
    cursor: pointer !important;
  }
`;
