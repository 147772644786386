import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { useContextSelector } from "use-context-selector";
import { StoreListTypes } from "../../../../../../@types/Stores";
import {
  TableComponent,
  TdComponent,
  ThComponent,
  TrComponent,
} from "../../../../../../components/TableElements";
import { StoresContext } from "../../../../../../contexts/StoresContext";

import * as S from "./styles";

interface WebStoreListProps {
  storesData: StoreListTypes[];
  storesLoading: boolean;
  handleSetSort?: (sortValue: string) => void;
  selectSortBy: (value: string) => void;
}

// interface ModalConfirmStatusChangeProps {
//   shopSelected: StoreListTypes;
//   isOpen: boolean;
//   status: boolean;
// }

export function WebStoreList({
  storesLoading,
  storesData,
  handleSetSort,
  selectSortBy,
}: WebStoreListProps) {
  // const [storeStatus, setStoreStatus] = useState("Active");
  // const [modalConfirmStatusChange, setModalConfirmStatusChange] = useState<ModalConfirmStatusChangeProps>({
  //   shopSelected: {} as StoreListTypes,
  //   isOpen: false,
  //   status: true,
  // });

  const navigate = useNavigate();

  // const handleChangeStatus = (
  //   status: boolean,
  //   shopSelected: StoreListTypes,
  // ) => {
  //   setModalConfirmStatusChange(() => ({
  //     shopSelected,
  //     isOpen: true,
  //     status,
  //   }));

  // };

  return (
    <S.WebStoreListContainer storesLoading={storesLoading}>
      {storesLoading ? (
        <ReactLoading height={35} width={35} type={"spin"} color="#000" />
      ) : (
        <TableComponent>
          <thead>
            <tr>
              <ThComponent
                arrowUpFunction={() => selectSortBy("code_desc")}
                arrowDownFunction={() => selectSortBy("code_asc")}
              >
                Client ID
              </ThComponent>
              <ThComponent>Region</ThComponent>
              <ThComponent
                arrowUpFunction={() => selectSortBy("name_desc")}
                arrowDownFunction={() => selectSortBy("name_asc")}
              >
                Store name
              </ThComponent>
              <th />
              {/* <ThComponent
                arrowUpFunction={() => selectSortBy("status_desc")}
                arrowDownFunction={() => selectSortBy("status_asc")}
              >
                Status
              </ThComponent> */}
              <ThComponent withArrows={false}>Actions</ThComponent>
            </tr>
          </thead>

          <tbody>
            {storesData &&
              storesData.map((store) => (
                <TrComponent key={store.id}>
                  <TdComponent tdWidth={"20%"}>{store.code}</TdComponent>
                  <TdComponent tdWidth={"20%"}>{store.tenantName}</TdComponent>
                  <TdComponent tdWidth={"25%"}>{store.name}</TdComponent>
                  <TdComponent tdWidth={"25%"}>
                    {/* <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <S.StatusButton variant={storeStatus}>
                          <p>{storeStatus} |</p>
                          <MdKeyboardArrowDown size={24} />
                        </S.StatusButton>
                      </DropdownMenu.Trigger>

                      <Dropdown>
                        <S.DropdownButton
                          onClick={() => handleChangeStatus(true, store)}
                        >
                          Active
                        </S.DropdownButton>
                        <S.DropdownSeparator />
                        <S.DropdownButton
                          variant="Inactive"
                          onClick={() => handleChangeStatus(false, store)}
                        >
                          Inactive
                        </S.DropdownButton>
                      </Dropdown>
                    </DropdownMenu.Root> */}
                  </TdComponent>
                  <TdComponent
                    withActions
                    editActionTitle="Edit store"
                    deleteActionTitle="Delete store"
                    deleteActionModalTitle={`Do you want to delete the store ${store.name} ?`}
                    deleteActionFunction={() => { }}
                  />
                </TrComponent>
              ))}
          </tbody>
        </TableComponent>
      )}

      {/* <ConfirmationModal
        title="Are you sure you want to change the store status?"
        opened={modalConfirmStatusChange.isOpen}
        confirmFunction={() =>
          updateStoreStatus(
            modalConfirmStatusChange.status,
            modalConfirmStatusChange.shopSelected.tenantName,
            modalConfirmStatusChange.shopSelected.code,
          )
        }
        closeModal={() =>
          setModalConfirmStatusChange((state) => ({
            ...state,
            isOpen: false,
          }))
        }
      /> */}
    </S.WebStoreListContainer>
  );
}
