import styled from "styled-components";

export const ReportContainer = styled.main`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 3rem;
  background-color: ${(props) => props.theme.colors["white-300"]};
  gap: 1.5rem;
`;
