import styled from "styled-components";

export const StoreContainer = styled.main`
  padding: 3rem;
`;

export const StoreHeader = styled.header`
  display: flex;

  gap: 1.5rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;

  border-bottom: 1px solid ${(props) => props.theme.colors["gray-400"]};
`;

export const StoreFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  span {
    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors.black};

    margin-bottom: 0.5rem;
  }
`;

export const StoresContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 1.5rem;
`;

export const StoresCardContainer = styled.div`
  display: flex;
  justify-content: center;

  padding: 1.5rem;

  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.SMM};
  box-shadow: 0px 4px 8px rgba(138, 138, 138, 0.08);

  width: 16rem;
  height: 21rem;

  button {
    min-height: 2.5rem;
    margin-top: auto;

    background-color: ${(props) => props.theme.colors["blue-600"]};
    border-radius: ${(props) => props.theme.borderRadius.XXLG};
    border: none;

    font-weight: ${(props) => props.theme.fontWeight.Semibold};
    color: ${(props) => props.theme.colors.white};

    &:disabled {
      background-color: ${(props) => props.theme.colors["gray-400"]};
      color: ${(props) => props.theme.colors["gray-600"]};
    }

    &:not(:disabled):hover {
      background-color: ${(props) => props.theme.colors["blue-650"]};
      outline: 2px solid ${(props) => props.theme.colors["blue-500"]};
      transition: all 0.2s;
    }
  }
`;

export const StoreCardContent = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 13.12rem;
    height: 7.5rem;

    margin-bottom: 1.5rem;
  }
`;

export const StoreCardInfoContent = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 1rem;

  gap: 0.375rem;

  span {
    font-family: ${(props) => props.theme.fontFamily.Sora};
    font-size: ${(props) => props.theme.fontSize.XXXS};
    color: ${(props) => props.theme.colors["gray-900"]};
    text-transform: capitalize;
  }

  svg {
    color: ${(props) => props.theme.colors["gray-900"]};
  }
`;
