export const StoreRegionsOptions = [
  { id: "1", value: "west", label: "West" },
  { id: "2", value: "midwest", label: "Midwest" },
  { id: "3", value: "south", label: "South" },
  { id: "4", value: "northEast", label: "North East" },
  { id: "5", value: "canada", label: "Canada" },
  { id: "6", value: "outsideUS", label: "Outside US" },
];

export const StoresListOrderBy = [
  { id: "1", value: "alphabetical", label: "Alphabetical order" },
  {
    id: "2",
    value: "alphabeticalLocation",
    label: "Alphabetical location",
  },
  { id: "3", value: "popular", label: "Most popular" },
];

export const StoreStatusOptions = [
  { id: "1", value: "true", label: "Active" },
  { id: "2", value: "false", label: "Inactive" },
];

export const StoreTypeOptions = [
  { id: "1", value: "SHOP", label: "Shop" },
  { id: "2", value: "WAREHOUSE", label: "Warehouse" },
];

export const PrinterSizeOptions = [
  { id: "1", value: "Small", label: "Small" },
  { id: "2", value: "Large", label: "Large" },
];
