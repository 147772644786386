import React from "react";
import { Button } from "../../../../../../../components/Button";
import { FormInput } from "../../../../../../../components/FormElements/FormInput";

import * as S from "./styles";

export function ApisStore() {
  const error = "";

  return (
    <S.StoreApisForm>
      <S.StoreFormTitle>Link a LightSpeed account</S.StoreFormTitle>

      <S.StoreInputAlign>
        <FormInput
          title='API'
          type='text'
          placeholder='Enter API'
          autoComplete='new-password'
          error={error}
        />

        <FormInput
          title='API'
          type='text'
          placeholder='Enter ID'
          autoComplete='new-password'
          error={error}
        />
      </S.StoreInputAlign>

      <S.StoreInputAlign>
        <FormInput
          title='Printer IP'
          type='email'
          placeholder='Enter API Key'
          autoComplete='new-password'
          error={error}
        />

        <FormInput
          title='Printer IP'
          type='email'
          placeholder='Enter Secret'
          autoComplete='new-password'
          error={error}
        />
      </S.StoreInputAlign>

      <S.ButtonContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Submit
        </Button>
      </S.ButtonContainer>
    </S.StoreApisForm>
  );
}
