import { MdLandscape } from "react-icons/md";
import styled from "styled-components";

export const StoreImageForm = styled.form``;

export const StoreImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 2rem;

  @media (max-width: 1210px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StoreFormTitle = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-size: ${(props) => props.theme.fontSize.XS};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  color: ${(props) => props.theme.colors["gray-900"]};

  margin: 2rem 0;
`;

export const StoreImageWrapper = styled.div`
  display: flex;

  gap: 1.5rem;

  input {
    display: none;
  }
`;

export const StoreImagePicture = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  background: #e8e8e8;

  width: 16rem;
  height: 11rem;

  border: 1px dashed ${(props) => props.theme.colors["blue-600"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};

  cursor: pointer;

  img {
    width: 15.75rem;
    height: 10.75rem;

    object-fit: contain;
  }
`;

export const StoreImageIcon = styled(MdLandscape)`
  width: 74px;
  height: 74px;
  color: ${(props) => props.theme.colors["gray-800"]};
`;

export const StoreImageAlignContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;
`;

export const StoreImageDescription = styled.span`
  font-family: ${(props) => props.theme.fontFamily.Sora};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
`;

export const SendImageButton = styled.button`
  display: flex;
  align-items: center;

  padding: 1rem;

  border-radius: ${(props) => props.theme.borderRadius.SM};
  border: none;

  background-color: ${(props) => props.theme.colors["blue-600"]};
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-family: ${(props) => props.theme.fontFamily.Sora};

  &:hover {
    background-color: ${(props) => props.theme.colors["blue-650"]};
    outline: 2px solid ${(props) => props.theme.colors["blue-500"]};
    transition: all 0.2s;
  }

  img {
    height: 1.5rem;
    width: 1.5rem;
  }
`;
