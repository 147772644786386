import { useContextSelector } from "use-context-selector";
import { StoresContext } from "../../../../../../contexts/StoresContext";

import * as S from "./styles";

export function CreateStoreStepsHeader() {
  const { createStoreStep, setCreateStoreStep } = useContextSelector(
    StoresContext,
    (context) => {
      return context;
    }
  );

  return (
    <S.CreateStoreStepsContainer>
      <S.StepsButton
        active={createStoreStep === "details"}
        onClick={() => setCreateStoreStep("details")}
      >
        Store Details
      </S.StepsButton>
      <S.StepsButton
        active={createStoreStep === "images"}
        disabled
        onClick={() => setCreateStoreStep("images")}
      >
        Images
      </S.StepsButton>
      <S.StepsButton
        active={createStoreStep === "printers"}
        onClick={() => setCreateStoreStep("printers")}
      >
        Printers
      </S.StepsButton>
      <S.StepsButton
        active={createStoreStep === "api"}
        onClick={() => setCreateStoreStep("api")}
      >
        API's
      </S.StepsButton>
    </S.CreateStoreStepsContainer>
  );
}
