import styled, { css } from "styled-components";

export const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface InputTitle {
  variant?: "light";
}

export const InputTitle = styled.span<InputTitle>`
  font-size: ${(props) => props.theme.fontSize.XXXS};
  font-weight: ${(props) => props.theme.fontWeight.Semibold};
  font-family: ${(props) => props.theme.fontFamily.Sora};
  color: ${(props) => props.theme.colors.black};

  margin-bottom: 0.5rem;

  ${(props) =>
    props.variant === "light" &&
    css`
      font-size: ${(props) => props.theme.fontSize.XXS};
      font-weight: ${(props) => props.theme.fontWeight.Regular};
      color: ${(props) => props.theme.colors["black-925"]};
    `};
`;

interface InputProps {
  variant?: "form" | "small" | "xsmall" | "xlarge";
  disabled?: boolean;
}

export const SearchInputWrapper = styled.div<InputProps>`
  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.colors.white};
  outline: 1px solid ${(props) => props.theme.colors["gray-400"]};
  border-radius: ${(props) => props.theme.borderRadius.SM};

  max-height: 3rem;
  padding: 0 0.5rem;

  &:hover {
    outline: 1px solid ${(props) => props.theme.colors["gray-650"]};
  }

  svg {
    margin-right: 0.5rem;
    color: ${(props) => props.theme.colors["gray-800"]};
  }

  input {
    flex: 1;
    padding: 1rem 0;
    border: none;

    max-height: 3rem;

    background-color: ${(props) => props.theme.colors.white};

    font-family: ${(props) => props.theme.fontFamily.Sora};
    color: ${(props) => props.theme.colors["gray-800"]};

    @media (max-width: 1050px) {
      width: 10rem;
    }

    ${({ variant }) =>
      variant === "form" &&
      css`
        font-size: ${(props) => props.theme.fontSize.XXXS};
        padding: 1rem 0.5rem;
      `};

    ${({ variant }) =>
      variant === "small" &&
      css`
        max-width: 12rem;
      `};

    ${({ variant }) =>
      variant === "xsmall" &&
      css`
        max-width: 9rem;
      `};

    ${({ variant }) =>
      variant === "xlarge" &&
      css`
        width: 25rem;
      `};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 1;

      &:hover {
        outline: 1px solid ${(props) => props.theme.colors["gray-400"]};
      }

      input {
        color: ${(props) => props.theme.colors["black-750"]};
      }
    `}
`;
