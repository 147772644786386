import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import ReactLoading from "react-loading";
import { Dropdown } from "../../../../../../components/Dropdown";
import {
  TableComponent,
  TdComponent,
  ThComponent,
  TrComponent,
} from "../../../../../../components/TableElements";
import { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

import * as S from "./styles";

interface WebPrintersListProps {
  printersData: {
    id: string;
    description: string;
    printerIp: string;
    status?: string;
  }[];
}

export function WebPrintersList({ printersData }: WebPrintersListProps) {
  const [storeStatus, setStoreStatus] = useState("Active");

  const storesLoading = false;

  const handleChangeStatus = (status: string) => {
    setStoreStatus(status);
  };

  return (
    <S.WebPrintersListContainer storesLoading={storesLoading}>
      {storesLoading ? (
        <ReactLoading height={35} width={35} type={"spin"} color='#000' />
      ) : (
        <TableComponent>
          <thead>
            <tr>
              <ThComponent>ID</ThComponent>
              <ThComponent>Description</ThComponent>
              <ThComponent>Printer IP</ThComponent>
              <ThComponent>Status</ThComponent>
            </tr>
          </thead>

          <tbody>
            {printersData &&
              printersData.map((printer) => (
                <TrComponent key={printer.id}>
                  <TdComponent tdWidth={"35%"}>{printer.id}</TdComponent>
                  <TdComponent tdWidth={"35%"}>
                    {printer.description}
                  </TdComponent>
                  <TdComponent tdWidth={"30%"}>{printer.printerIp}</TdComponent>
                  <TdComponent>
                    <DropdownMenu.Root>
                      <DropdownMenu.Trigger asChild>
                        <S.StatusButton variant={storeStatus}>
                          <p>{storeStatus} |</p>
                          <MdKeyboardArrowDown size={24} />
                        </S.StatusButton>
                      </DropdownMenu.Trigger>

                      <Dropdown>
                        <S.DropdownButton
                          onClick={() => handleChangeStatus("Active")}
                        >
                          Active
                        </S.DropdownButton>
                        <S.DropdownSeparator />
                        <S.DropdownButton
                          variant='Inactive'
                          onClick={() => handleChangeStatus("Inactive")}
                        >
                          Inactive
                        </S.DropdownButton>
                      </Dropdown>
                    </DropdownMenu.Root>
                  </TdComponent>
                </TrComponent>
              ))}
          </tbody>
        </TableComponent>
      )}
    </S.WebPrintersListContainer>
  );
}
